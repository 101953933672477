import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

const whiteLogos = [
  'https://myorderportal.com/pictures/frankspizzaoakridge.png',
  'https://myorderportal.com/pictures/brendaleedover.png',
  'https://myorderportal.com/pictures/somersetpizza.png',
  'https://myorderportal.com/pictures/littleitalybrickoven.png',
  'https://myorderportal.com/pictures/annabelspizzaunionville.png',
  'https://myorderportal.com/pictures/pezzopizzauno.png',
  'https://myorderportal.com/pictures/pizzarusticarockaway.png',
  'https://myorderportal.com/pictures/lacasadeltaco.png',
  'https://myorderportal.com/pictures/rivolipizzeriaunion.png',
  'https://myorderportal.com/pictures/vincenzopizzabuddlake.png',
  'https://myorderportal.com/pictures/romanpizzablairstown.png',
  'https://myorderportal.com/pictures/unclesalssparta.png',
  'https://myorderportal.com/pictures/frankspizzaedison.png',
  'https://myorderportal.com/pictures/villacaprinewton.png',
  'https://myorderportal.com/pictures/pizzaexpressblairstown.png',
  'https://myorderportal.com/pictures/lacocinademaria.png',
  'https://myorderportal.com/pictures/newyorkpizzagrandisland.png',
  'https://myorderportal.com/pictures/jefryspizzaparsippany.png'
];

const darkLogos = [
  'https://myorderportal.com/pictures/frankspizzaoakridge.png',
  'https://myorderportal.com/pictures/brendaleedover.png',
  'https://myorderportal.com/pictures/somersetpizza.png',
  'https://myorderportal.com/pictures/littleitalybrickoven.png',
  'https://myorderportal.com/pictures/annabelspizzaunionville.png',
  'https://myorderportal.com/pictures/pezzopizzauno.png',
  'https://myorderportal.com/pictures/pizzarusticarockaway.png',
  'https://myorderportal.com/pictures/lacasadeltaco.png',
  'https://myorderportal.com/pictures/rivolipizzeriaunion.png',
  'https://myorderportal.com/pictures/vincenzopizzabuddlake.png',
  'https://myorderportal.com/pictures/romanpizzablairstown.png',
  'https://myorderportal.com/pictures/unclesalssparta.png',
  'https://myorderportal.com/pictures/frankspizzaedison.png',
  'https://myorderportal.com/pictures/villacaprinewton.png',
  'https://myorderportal.com/pictures/pizzaexpressblairstown.png',
  'https://myorderportal.com/pictures/lacocinademaria.png',
  'https://myorderportal.com/pictures/newyorkpizzagrandisland.png',
  'https://myorderportal.com/pictures/jefryspizzaparsippany.png'
  
];

const logoStyle = {
  width: '100px',
  height: '80px',
  margin: '0 32px',
  opacity: 0.7,
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="h4"
        mb={2}
        align="center"
        color="text.secondary"
      >
        Join Our Network
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.7 }}>
        {logos.map((logo, index) => (
          <Grid item key={index} >
            <img
              src={logo}
              alt={`logos`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}